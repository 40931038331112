<template>
  <div class="animated fadeIn">
    <div class="animated fadeIn">
      <table-custom
        ref="dataTable"
        :name="`${$customTable.getCustomTableName($route.meta.module)}`"
        :loading="dataTable.isLoading"
        :data="dataTable.dataSet"
        :options="dataTable.options"
      >
        <div slot="afterFilter">
          <b-row v-if="showAddButton">
            <b-col>
              <b-button-group>
                <button
                  class="btn btn-outline-dark btn"
                  type="button"
                  @click="addItem()"
                >
                  <i class="fa fa-toolbar fa-plus"></i> Create
                </button>
              </b-button-group>
            </b-col>
          </b-row>
        </div>
        <div slot="custom-actions" slot-scope="props">
          <div class="btn-group">
            <button
              :class="[
                props.row['tasks_total']
                  ? 'btn btn-secondary btn-sm'
                  : 'btn btn-outline-secondary btn-sm'
              ]"
              @click="toggleChildRow(props.row)"
            >
              <font-awesome-icon icon="tasks" />
            </button>
          </div>
        </div>

        <span slot-scope="props" slot="child_row">
          <PickListItemBottlingsTable
            ref="item-bottlings"
            :pick-list-id="pickListId"
            :parent-row="props.row"
            :read-only="readOnly"
            @loaded="onChildTableLoad"
          ></PickListItemBottlingsTable>
        </span>
      </table-custom>
    </div>
  </div>
</template>

<script>
import { FORM_MODE } from '@/shared/constants'
import warehouseServices from '@/services/warehouse.service'
import PickListItemBottlingsTable from './PickListItemBottlingsTable.vue'

export default {
  name: 'PickListItemsTable',
  props: {
    pickListId: {
      type: [String, Number],
      default: ''
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    mode: {
      type: [Number],
      default: FORM_MODE.VIEW
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PickListItemBottlingsTable
  },
  data: function () {
    return {
      filter: null,
      pickListBottlings: [],
      dictionaries: {
        products: []
      },
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: 'ID',
          readOnly: this.readOnly,
          showChildRowToggler: false,
          filterable: [
            //  "Finished Product ID",
            'Finished Product',
            'Bottle Size',
            'Bottles',
            'Bottles PL',
            'Bottles Difference',
            'Number of Cases',
            'Cases PL',
            'Cases Difference',
            'Cost per Case',
            'Line Cost',
            'Organic Status',
            'Warehouse'
          ],
          columns: [
            //  "Finished Product ID",
            'Finished Product',
            'Bottle Size',
            'Bottles',
            'Bottles PL',
            'Bottles Difference',
            'Number of Cases',
            'Cases PL',
            'Cases Difference',
            'Cost per Case',
            'Line Cost',
            'Organic Status',

            'Warehouse',

            'Actions'
          ],
          editableColumns: [],
          perPage: 50,
          showCustomActions: true,
          showChildRows: true,
          cellClasses: {
            Bottles: [
              {
                class: 'bg-info',
                condition: row => +row['Bottles'] > 0
              }
            ],
            'Bottles Difference': [
              {
                class: 'bg-danger',
                condition: row => +row['Bottles Difference'] > 0
              },
              {
                class: 'bg-success',
                condition: row => +row['Bottles Difference'] == 0
              }
            ],
            'Cases Difference': [
              {
                class: 'bg-danger',
                condition: row => +row['Cases Difference'] > 0
              },
              {
                class: 'bg-success',
                condition: row => +row['Cases Difference'] == 0
              }
            ]
          }
        },
        childRow: {
          showTasks: false
        }
      }
    }
  },
  created () {
    this.initialize()
  },
  async mounted () {
    this.dictionaries.products = await this.$api.get(
      `/dictionaries/finished-products`
    )
  },
  methods: {
    async initialize () {},
    async fetchData (payload) {
      //console.log('PickListItemsTable.fetchData', payload)

      if (payload) this.filter = payload

      if (!this.filter) {
        console.log('PickListItemsTable.filter undefined')
        return
      }

      this.dataTable.isLoading = true

      /*
      this.pickListBottlings = await warehouseServices.fetchPickListBottlings({
        picklist_id: payload.picklist_id
      });
      */
      console.log('PickListItemsTable.fetchData.filter:', this.filter)
      let response = await warehouseServices.fetchPickListItems(this.filter)

      response.forEach(row => {
        row['ID'] = this.$helpers.uuidv4()
      })

      this.rawData = response

      this.dataTable.isLoading = false

      this.dataTable.dataSet = this.rawData

      this.$emit('loaded', this.dataTable.dataSet.length)
    },
    onChildTableLoad (e) {
      let parentRow = this.dataTable.dataSet.find(
        i => i['ID'] === e.parentRow['ID']
      )

      let bottlesPl = e.data.reduce((a, b) => {
        return +a + +b['Bottles']
      }, 0)

      let productId = parentRow['Finished Product ID']
      /*
      console.log(
        'this.dictionaries.products',
        productId,
        this.dictionaries.products
      )
        */
      let product = this.dictionaries.products.find(p => p.id == productId)
      let bottlesPerCase = 6

      /*
      console.log('PickListItemsTable.onChildTableLoad.parentRow:', parentRow)
      console.log(
        'PickListItemsTable.onChildTableLoad.product:',

        product
      )*/

      if (product.product_group) {
        bottlesPerCase = product.case_size_bottles
      }
      /*
      console.log(
        'PickListItemsTable.onChildTableLoad.parentRow.bottlesPerCase:',
        parentRow,
        bottlesPerCase
      )
      */
      parentRow['Bottles PL'] = bottlesPl
      parentRow['Bottles Difference'] = +parentRow['Bottles'] - bottlesPl
      parentRow['Number of Cases'] = this.$helpers.round(
        parentRow['Bottles'] / bottlesPerCase,
        2
      )
      parentRow['Cases PL'] = this.$helpers.round(
        parentRow['Bottles PL'] / bottlesPerCase,
        2
      )
      parentRow['Cases Difference'] = this.$helpers.round(
        parentRow['Number of Cases'] - parentRow['Cases PL'],
        2
      )

      parentRow['Line Cost'] =
        +parentRow['Cases PL'] * +parentRow['Cost per Case']
    },
    toggleChildRow (row) {
      this.dataTable.childRow.showTasks = !(this.dataTable.childRow.showFiles = false)

      this.$refs.dataTable.toggleChildRow(row['ID'])

      //this.getLinkedBottlings(row);

      this.$nextTick(() => {
        if (this.$refs['item-bottlings'])
          this.$refs['item-bottlings'].fetchData()
      })
    },
    /*
    getLinkedBottlings(row) {
      let result = [];
      result = this.pickListBottlings.filter(
        i =>
          i["Finished Product ID"] == row["Finished Product ID"] &&
          i["Organic Status ID"] == row["Organic Status_ID"] &&
          i["Bottle Size"] == row["Bottle Size"] &&
          i["Cost per Case"] == row["Cost per Case"]
      );

      this.$nextTick(() => {
        if (this.$refs["item-bottlings"])
          this.$refs["item-bottlings"].updateDataSet(result);
      });

      return result;
    },
    */
    addItem () {}
  }
}
</script>

<style scoped></style>
