<template>
  <div class="animated fadeIn">
    <b-card>
      <DetailsFormWrapper
        ref="form"
        :id="id"
        :model="model"
        :data="data"
        :custom-buttons="customButtons"
        @custom-click="
          name => {
            this[name]()
          }
        "
        header="Pick list"
        @loaded="onFormLoad"
      >
        <slot name="content">
          <b-tabs content-class="mt-3" justified>
            <b-tab title="Overview" active>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    v-model="data['Incoming Order']"
                    :properties="entity('Incoming Order')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>

                <b-col lg="2" md="6" sm="6">
                  <FormInput
                    v-model="data['PO Number']"
                    :properties="entity('PO Number')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="1" md="6" sm="6">
                  <FormInput
                    v-model="data['PO Confirmed']"
                    :properties="entity('PO Confirmed')"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    v-model="data['Warehouse']"
                    :properties="entity('Warehouse')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    v-model="data['Distributor']"
                    :properties="entity('Distributor')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormDateTime
                    v-model="data['PO Date']"
                    :properties="entity('PO Date')"
                    @change="onFieldChange"
                  ></FormDateTime>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormDateTime
                    v-model="data['Picklist Date']"
                    :properties="entity('Picklist Date')"
                    @change="onFieldChange"
                  ></FormDateTime>
                </b-col>

                <b-col lg="1" md="6" sm="6">
                  <FormSelect
                    v-model="data['Currency']"
                    :properties="entity('Currency')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
                <b-col lg="2" md="6" sm="6">
                  <FormInput
                    v-model="data['Status']"
                    :properties="entity('Status')"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Invoice Number']"
                    :properties="entity('Invoice Number')"
                  ></FormInput>
                </b-col>
              </b-row>

              <b-row v-if="false">
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Created By']"
                    :properties="entity('Created By')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Created']"
                    :properties="entity('Created')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Modified By']"
                    :properties="entity('Modified By')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Modified']"
                    :properties="entity('Modified')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Created By']"
                    :properties="entity('Created By')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Created']"
                    :properties="entity('Created')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Modified By']"
                    :properties="entity('Modified By')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Modified']"
                    :properties="entity('Modified')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
              </b-row>
              <hr />

              <PickListItemsTable
                ref="picklist-items"
                :pick-list-id="id"
                :show-add-button="false"
                :read-only="isReadOnlyRecord"
                @changed="onItemsTableChange"
              ></PickListItemsTable>
            </b-tab>
            <b-tab
              :title="controls.tabs.files.title"
              v-if="$form.mode(this) !== $constants.FORM_MODE.CREATE"
            >
              <files-container
                :module-id="$route.meta.module.id"
                :entity-id="id"
                @loaded="onFileContainerLoad"
              />
            </b-tab>
          </b-tabs>
        </slot>
      </DetailsFormWrapper>
    </b-card>
  </div>
</template>

<script>
import models from '@/models'
import modelHelpers from '@/models/helpers'
import PickListItemsTable from '@/views/Warehouse/PickLists/PickListItemsTable'
import warehouseServices from '@/services/warehouse.service'

export default {
  name: 'IncomingOrderSubmission',
  components: { PickListItemsTable },
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data: function () {
    return {
      isReadOnlyRecord: false,
      modelHelpers: modelHelpers,
      model: models.warehouse.pickLists,
      data: {},
      controls: {
        tabs: {
          files: {
            title: 'Files'
          }
        }
      },
      customButtons: [
        {
          text: 'Convert to invoice',
          icon: 'receipt',
          loading: false,
          visible: false,
          method: 'convert2invoice',
          variant: 'outline-dark'
        },
        {
          text: 'Open incoming order',
          icon: 'receipt',
          loading: false,
          visible: false,
          method: 'openIncomingOrder',
          variant: 'outline-dark'
        },
        {
          text: 'Open invoice',
          icon: 'receipt',
          loading: false,
          visible: false,
          method: 'openInvoice',
          variant: 'outline-dark'
        }
      ],
      entity: modelHelpers.entity
    }
  },
  computed: {},
  created () {
    this.setDefaults()
  },
  async mounted () {},
  methods: {
    async convert2invoice () {
      let confirm = await this.$form.showConfirmation(
        `Picklist will be converted to invoice. Do you want to proceed?`
      )

      if (!confirm) return

      this.customButtons[0].loading = true

      let result = await this.$api.post(
        `warehouse/pick-list/${this.id}/convert`
      )

      if (result.status !== 200) this.$form.makeToastError(result.message)

      this.customButtons[0].loading = false

      if (result.id) {
        this.$form.makeToastInfo('Success')

        this.$refs['form'].initialize()
      }
    },
    async openIncomingOrder () {
      this.$router.push({
        name: 'Incoming order submission',
        params: { mode: 'view', id: this.data['Incoming Order ID'] }
      })
    },
    async openInvoice () {
      this.$router.push({
        name: 'WH Invoice submission',
        params: { mode: 'view', id: this.data['Invoice Number'] }
      })
    },
    setDefaults () {
      this.data = modelHelpers.getEmptyEntitiesObject(this.model.entities)

      let incomingOrder = this.model.entities.find(
        i => i.name == 'Incoming Order'
      )

      incomingOrder.readonly =
        this.$form.mode(this) !== this.$constants.FORM_MODE.CREATE
    },

    onFormLoad (payload) {
      if (!payload) return

      this.data = { ...this.data, ...payload }

      this.data['Incoming Order'] = {
        id: payload['Incoming Order ID'],
        label: payload['Incoming Order']
      }

      this.data['Warehouse'] = {
        id: payload['Warehouse ID'],
        label: payload['Warehouse']
      }

      this.data['Distributor'] = {
        id: payload['Account ID'],
        label: payload['Account Name']
      }

      this.data['Currency'] = {
        id: payload['Currency ID'],
        label: payload['Currency']
      }

      this.customButtons[0].visible = !(
        this.$form.mode(this) == this.$constants.FORM_MODE.CREATE ||
        (this.data && this.data['Invoice Number'] > 0)
      )

      this.customButtons[1].visible = this.data['Incoming Order ID'] > 0
      this.customButtons[2].visible = this.data['Invoice Number'] > 0

      this.isReadOnlyRecord = !(
        this.model.actions.Edit && this.model.actions.Edit.validator(this.data)
      )

      this.fetchPickListItems()
    },
    async onFieldChange (e) {
      if (e.id == 'incoming_order') {
        let order = await warehouseServices.fetchIncomingOrder(e.value.id)

        this.data['Incoming Order'] = {
          id: e.value.id,
          label: e.value.label
        }

        this.data['PO Number'] = order['PO Number']
        this.data['PO Date'] = order['PO Date']
        this.data['Est Finish Date'] = order['Est Finish Date']

        this.data['Warehouse'] = {
          id: order['Warehouse ID'],
          label: order['Warehouse']
        }

        this.data['Distributor'] = {
          id: order['Account ID'],
          label: order['Account Name']
        }

        this.data['Currency'] = {
          id: order['Currency ID'],
          label: order['Currency']
        }

        this.fetchPickListItems()
      }

      if (this.$form.isViewMode(this))
        this.$refs['form']
          .save('view')
          .then(() => this.$refs['form'].initialize())
    },
    fetchPickListItems () {
      if (this.data['Incoming Order'] && this.data['Incoming Order'].id)
        this.$refs['picklist-items'].fetchData({
          inc_order_id: this.data['Incoming Order'].id,
          picklist_id: this.id
        })
    },
    onItemsTableChange () {},
    updateComputedFields () {},
    onFileContainerLoad (count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`
    }
  },
  watch: {}
}
</script>

<style></style>
